// src/App.js
import React from 'react';
import Chatbot from './Chatbot';
import styled from 'styled-components';

const AppContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #eef2f3;
`;

const App = () => {
    return (
        <AppContainer>
            <Chatbot />
        </AppContainer>
    );
};

export default App;
