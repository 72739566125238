// src/Chatbot.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
//import bearerToken from "./Token";
import LoadingSpinner from './Spinner'; // Import your spinner

// Import your logos
import botLogo from './assets/snowbot.svg';
import userLogo from './assets/userbot.svg';

const DefaultQuestionsContainer = styled.div`
    margin: 10px 0;
    display: flex;
    flex-direction: row; /* Display buttons in a single row */
    flex-wrap: wrap; /* Allow wrapping if necessary */
    justify-content: space-around; /* Space buttons evenly */
    gap: 10px; /* Space between buttons */
`;

const DefaultQuestionButton = styled.button`
    padding: 10px;
    width: 30%; /* Adjust width to fit three buttons in a row */
    max-width: 300px; /* Optional: Set a maximum width */
    border: none;
    border-radius: 20px;
    background: #4A90E2;
    color: white;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: #357ABD;
    }
`;

const QuestionsTitle = styled.h3`
    margin: 0; /* Remove default margin */
    font-size: 1.2em; /* Adjust the font size */
    text-align: center; /* Center align the title */
    color: #4A90E2; /* Match the theme color */
`;

const Disclaimer = styled.div`
    width: 90%; /* Adjust width to match chatbot */
    max-width: 800px; /* Optional: Set a max width */
    margin: 10px auto; /* Center the disclaimer */
    padding: 10px;
    background-color: #f8f9fa; /* Light background color */
    border: 1px solid #ddd; /* Border for distinction */
    border-radius: 5px; /* Rounded corners */
    text-align: center; /* Center the text */
    font-size: 0.9em; /* Slightly smaller text */
    color: #333; /* Darker text color */
`;

const ChatContainer = styled.div`
    width: 80%; /* Adjust this value to make the chatbot wider */
    max-width: 1400px; /* Optional: Set a max width */
    height: 800px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const Header = styled.div`
    background-color: #4A90E2;
    color: white;
    padding: 15px;
    text-align: center;
    font-size: 1.2em;
    font-weight: bold;
`;

const MessageList = styled.div`
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #F7F9FC;
`;

const MessageContainer = styled.div`
    display: flex;
    align-items: center;
    margin: 10px 0;
    max-width: 95%;
    width:auto;
    align-self: ${({ sender }) => (sender === 'user' ? 'flex-end' : 'flex-start')}; /* Align user messages to the right */
    flex-direction: ${({ sender }) => (sender === 'user' ? 'row-reverse' : 'row')}; /* Reverse direction for user */
`;

const Message = styled.div`
    padding: 10px;
    border-radius: 10px;
    background-color: ${({ sender }) => (sender === 'user' ? '#4A90E2' : '#E5E5EA')};
    color: ${({ sender }) => (sender === 'user' ? 'white' : 'black')};
`;

const Logo = styled.img`
    width: 40px; /* Set your logo size */
    height: 40px; /* Set your logo size */
    border-radius: 50%; /* Make it circular */
    margin-right: 10px; /* Space between logo and message */
    margin-left: ${({ sender }) => (sender === 'user' ? '0' : '10px')}; /* Adjust margin based on sender */
`;

const InputContainer = styled.div`
    display: flex;
    padding: 10px;
    background-color: #ffffff;
    border-top: 1px solid #ddd;
`;

const Input = styled.input`
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    outline: none;

    &:focus {
        border-color: #4A90E2;
    }
`;

const Button = styled.button`
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    background: #4A90E2;
    color: white;
    cursor: pointer;
    margin-left: 10px;
    transition: background 0.3s;

    &:hover {
        background: #357ABD;
    }
`;


const Chatbot = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false); // Loading state
    const [jwtToken, setJwtToken] = useState('');

    useEffect(() => {
        const fetchToken = async () => {
            try {
		//const response = await axios.get('https://releasenotes.snowflakecloud.org:5000/api/token');
                //const response = await axios.get('https://100.28.141.62:5000/api/token');
                setJwtToken("ABCHJBHB");
            } catch (error) {
                console.error('Error fetching JWT token:', error);
            }
        };
        fetchToken();
    }, []);

    const authValue = 'Bearer ' + jwtToken

    const createMarkup = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        const parts = text.split(urlRegex);
        
        return parts.map((part, index) => {
            if (urlRegex.test(part)) {
                return <a key={index} href={part} target="_blank" rel="noopener noreferrer">{part}</a>;
            }
            return part; // Return text as-is if not a link
        });
    };


    const handleSendMessage = async (userInput) => {
        const messageToSend = userInput || input;
        if (!messageToSend || !jwtToken) return;
        //console.log("I am Here")
        const userMessage = { text: messageToSend, sender: 'user' };
        setMessages((prevMessages) => [...prevMessages, userMessage]);
        setInput('');
        setLoading(true);
        setIsLoading(true); // Set loading state to true
        //const authValue = 'Bearer ' + jwtToken
        //console.log(messageToSend)
        try {

            //const data = { "query": input }
    
            const data = { "query": messageToSend }
            const header1 = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Snowflake-Authorization-Token-Type': 'KEYPAIR_JWT',
                'Authorization': authValue
                }
            const header = {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    }
            const postData = {
                        query : messageToSend 
                      };        
            //console.log( sqlstatement)
            //console.log( data)
            //console.log( header)
            //const response = await axios.post('https://NYTNYZE-IKB66726.snowflakecomputing.com/api/v2/databases/RELEASE_NOTES/schemas/CORE_DATA/cortex-search-services/BCR_RELEASE_SEARCH:query', data, {headers: header});
            const response = await axios.post('https://yd63mbazdaufyzryuyhw7nhgny0gfvzv.lambda-url.us-east-1.on.aws', postData, {timeout:60000, headers: header});
            //console.log( response)
            //console.log( response.data)
            //console.log( response.data.generatedResponse)
            
            //console.log('Response:', response.data);    
            //const  botMessage = bearerToken()
            //const botMessage = { text: bearerToken(), sender: 'bot' };
            //const botMessage = { text: response.data.answer, sender: 'bot' };
            const botMessage = { text: response.data.generatedResponse, sender: 'bot' };
            setMessages((prevMessages) => [...prevMessages, botMessage]);
        } catch (error) {
            const errorMessage = { text: 'Error fetching response', sender: 'bot' };
            setMessages((prevMessages) => [...prevMessages, errorMessage]);
        } finally {
            setIsLoading(false); // Set loading state to false after response
            setLoading(false);
        }

        setInput('');
    };

    // Default questions to be displayed as buttons
    const defaultQuestions = [
        "Iceberg Release Notes",
        "Cortex Search BCR",
        "All release notes for ACCOUNT_USAGE views"
    ];

    return (
        <ChatContainer>
            <Header>Snowflake Release Assistant</Header>
            <MessageList>
                {messages.map((message, index) => (
                    <MessageContainer key={index} sender={message.sender}>
                        <Logo src={message.sender === 'user' ? userLogo : botLogo} alt={`${message.sender} logo`} />
                        <Message sender={message.sender} dangerouslySetInnerHTML={{ __html: message.text }} >
                        </Message>
                    </MessageContainer>
                ))}
                {isLoading && <LoadingSpinner />} {/* Render the spinner here */}
            </MessageList>
            <InputContainer>
                <Input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                    placeholder="Type your message..."
		    disabled={isLoading}
                />
                <Button onClick={handleSendMessage} disabled={isLoading}>Send</Button>
            </InputContainer>
            <DefaultQuestionsContainer>
                <QuestionsTitle>Quick Questions</QuestionsTitle>
                {defaultQuestions.map((question, index) => (
                    <DefaultQuestionButton key={index} onClick={() => handleSendMessage(question)} disabled={isLoading}>
                        {question}
                    </DefaultQuestionButton>
                ))}
            </DefaultQuestionsContainer>
            <Disclaimer>
                This app operates independently and is not endorsed by or associated with Snowflake in any manner.
            </Disclaimer>
        </ChatContainer>
    );
};

export default Chatbot;
